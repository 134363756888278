
import { mapGetters, mapState } from 'vuex'
import { DELETE_USER_REQUEST } from '@/store/actions/user.ts'
import youSure from '@/utils/ConfirmationModal.ts'

export default{
    name: 'SubscriptionsTable',
    data() {
        return {
            filter: '',
        }
    },
    props: {
        accounts: {type: Array },
        fields: {type: Array },
        detail_fields: {type: Array},
    },
    methods: {
        deleteAccount(account) {
            this.$set(account, 'deleting', true)

            let subscriptions = this.subscriptions
                .filter(sub => sub.student_id === account.id)
                .map(sub => sub.payed)  // Map the subscriptions to payment info (usefull for the next step ;)
            let payed = subscriptions.filter(sub => sub)

            let you_sure_msg = null
            if (subscriptions.length > 0) {
                if (payed.length > 0) {
                    you_sure_msg = 'Zeker dat je dit account wilt verwijderen?<br /><br />' +
                        'Er is/zijn <b>' + subscriptions.length + '</b> inschrijving(en) (waarvan <b>' +
                        payed.length + '</b> al betaald) geregistreerd op dit account.<br />' +
                        'Inschrijvingen zullen ook worden verwijderd.'
                } else {
                    you_sure_msg = 'Zeker dat je dit account wilt verwijderen?<br /><br />' +
                        'Er is/zijn <b>' + subscriptions.length + '</b> inschrijving(en) geregistreerd op dit account.<br />' +
                        'Inschrijvingen zullen ook worden verwijderd.'
                }
            } else {
                you_sure_msg = 'Zeker dat je dit account wilt verwijderen?'
            }

            youSure(this, you_sure_msg)
                .then((res) => {
                    if (res) this.$store.dispatch(DELETE_USER_REQUEST, account.id)
                    else account.deleting = false
                })
                .catch((err) => {
                    alert('Het is niet gelukt om de gebruiker te verwijderen:\n' + err)
                    account.deleting = false
                })
        },
    },
    computed: {
        ...mapState({
            subscriptions: (state: any) => state.subscription.subscriptions
        }),
        ...mapGetters(['isAdmin']),
    },
    updated() {
        let items = this.$refs['accountsTable']
        this.$emit('active_items', items.computedItems)
    },
}
