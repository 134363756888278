function youSure(vm: any, msg: string) {
    const div = vm.$createElement
    let html_msg = div('div', { domProps: { innerHTML: msg } } )
    return new Promise((resolve, reject) => {
        vm.$bvModal.msgBoxConfirm(html_msg, {
            title: 'Confirm',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'Ja',
            cancelTitle: 'Nee',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                resolve(value)
            })
            .catch(err => {
                reject(err)
                // An error occurred
            })
    })
}

export default youSure
