
import AccountsTable from '@/components/account/AccountsTable.vue'
import FormModal from '@/components/account/FormModal.vue'
import { mapGetters, mapState } from 'vuex'

import youSure from '@/utils/ConfirmationModal.ts'
import AlertModal from '@/components/AlertModal.vue'

import { DELETE_STUDENTS_REQUEST } from '@/store/actions/user.ts'


export default {
    name: 'AccountsAdmin',
    components: {
        AccountsTable,
        FormModal,
        AlertModal,
    },
    data() {
        return {
            mounted: false,
            errors: [],
            success: null,

            account: {
                password: Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 7)
            },

            table_fields: [
                {
                    key: 'number',
                    label: 'Student nummer',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Voornaam',
                    sortable: true
                },
                {
                    key: 'last_name',
                    label: 'Achternaam',
                    sortable: true
                },
                {
                    key: 'role',
                    label: 'Account type',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: true
                },
            ],
            detail_fields: [
                {
                    key: 'grade',
                    label: 'Klas',
                },
                {
                    key: 'keuzedelen',
                    label: 'Keuzedelen',
                },
                {
                    key: 'hours',
                    label: 'X-Week punten',
                },
                {
                    key: 'phone',
                    label: 'Telefoonnummer'
                },
                {
                    key: 'email',
                    label: 'e-mail'
                },
            ],
        }
    },
    methods: {
        resetAccount() {
            this.account = {
                password: Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 7)
            }
        },
        updateAccount(value) {
            this.account = value
            this.$bvModal.show('account-form-modal')
        },
        accountAdded() {
            this.success = 'Account toegevoegd (inloggegevens zijn naar de nieuwe gebruiker gemailed).'
        },
        deleteAllStudents() {
            let you_sure_msg = 'Zeker dat je alle studenten accounts wilt verwijderen?<br /><br />' +
                'Inschrijving- en betalings-gegevens zullen hierdoor ook verloren gaan.'
            youSure(this, you_sure_msg)
                .then((res) => {
                    if (res) {
                        this.$store.dispatch(DELETE_STUDENTS_REQUEST)
                            .then(() => {
                                this.success = 'Alle studenten accounts zijn verwijderd'
                            })
                            .catch((err) => {
                                this.errors.push(err)
                            })
                    }
                })
        }
    },
    computed: {
        ...mapState({
            accounts: (state: any) => state.user.users,
            profiles_object: (state: any) => state.user.profiles_object,
        }),
        ...mapGetters(['isAdmin']),
        getAccounts() {
            if (!this.mounted) return
            return this.accounts.map(ac => {
                return {
                    ...this.profiles_object[ac.id],
                    ...ac
                }
            })
        },
    },
    mounted() {
        this.mounted = true
    },
}
