
import { mapState } from 'vuex'
import { SET_USER_REQUEST } from '@/store/actions/user.ts'

//import { CREATE_EXCURSION_REQUEST, UPDATE_EXCURSION_REQUEST } from '@/store/actions/excursion.ts'

export default {
    name: 'AccountForm',
    data: function() {
        return {
            mounted: false,

            email_valid: true,
            student_number_valid: true,
            password_valid: true,
        }
    },
    props: {
        account: {}
    },
    methods: {
        resetModal() {
            this.email_valid = true
            this.student_number_valid = true
            this.password_valid = true
            this.$emit('reset:account')
        },
        updateEmail: function(new_value) {
            let email_input = this.$refs['email-input']
            let email_warning = this.$refs['email-warning']
            if (
                !/[^@]+@(student\.)?scalda\.nl/.test(new_value) &&
                !/kenneth(_08@hotmail.com|\.poissonnier@gmail.com)/.test(new_value)
            ) {
                email_warning.innerHTML = 'E-mail adres moet @student.scalda.nl of @scalda.nl e-mail adres zijn'
                email_input.classList.add('is-invalid')
                this.email_valid = false
                return
            } else if (this.emails.includes(new_value)) {
                email_warning.innerHTML = 'Email adres komt al voor in database'
                email_input.classList.add('is-invalid')
                this.email_valid = false
                return
            }
            this.account.email = new_value // Actually set the value
            email_warning.innerHTML = ''
            email_input.classList.remove('is-invalid')
            this.email_valid = true
        },
        updateStudentNumber: function(new_value) {
            let student_number_input = this.$refs['number-input']
            let student_number_warning = this.$refs['number-warning']
            if (this.student_numbers.includes(new_value)) {
                student_number_warning.innerHTML = 'Studentnummer komt al voor in database'
                student_number_input.classList.add('is-invalid')
                this.student_number_valid = false
                return
            } else if (!/^\d{6}$/.test(new_value)) {
                student_number_warning.innerHTML = 'Studentnummer moet 6 cijfers bevatten'
                student_number_input.classList.add('is-invalid')
                this.student_number_valid = false
                return
            }
            this.account.number = new_value  // Actually set the value
            student_number_warning.innerHTML = ''
            student_number_input.classList.remove('is-invalid')
            this.student_number_valid = true
        },
        updatePassword: function(new_value) {
            let password_input = this.$refs['password-input']
            let password_warning = this.$refs['password-warning']
            if (new_value.length < 7) {
                password_warning.innerHTML = 'Password heeft een minimum lengte van 7 characters'
                password_input.classList.add('is-invalid')
                this.password_valid = false
                return
            }
            this.account.password = new_value  // Actual value setting
            password_warning.innerHTML = ''
            password_input.classList.remove('is-invalid')
            this.password_valid = true
        },
        validFields() {
            if (!this.email_valid) return false
            if (!this.password_valid) return false

            let ok = true

            var not_empty = ['role', 'email', 'first_name', 'last_name']
            if (!this.account.id) not_empty.push('password')

            if (this.account.role === 'student') {
                if (!this.student_number_valid) return false
                not_empty.push('number')
            }

            for (let i=0; i<not_empty.length; i++) {
                //let input = this.$refs[not_empty[i] + '-input']
                let warning = this.$refs[not_empty[i] + '-warning']
                if (!this.account[not_empty[i]]) {
                    //input.classList.add('is-invalid')
                    warning.innerHTML = 'Verplicht veld'
                    ok = false
                } else {
                    //input.classList.remove('is-invalid')
                    warning.innerHTML = ''
                }
            }

            return ok
        },
        async handleOk(bvModalEvt) {
            // Prevent submitting by default
            bvModalEvt.preventDefault()

            if (!this.validFields()) return

            let ok = await this.$store.dispatch(SET_USER_REQUEST, this.account)
                .then(() => {
                    return true
                })
                .catch((err) => {
                    alert('Toevoegen/Wijzigen is niet gelukt:\n' +
                        ('response' in err) ?
                        ('data' in err.response) ?
                            ('message' in err.response.data) ? err.response.data.message :
                                ('error' in err.response.data) ? err.response.data.error :
                                    err.response.data :
                            ('error' in err.response) ? err.response.error :
                                err.response :
                        err
                    )
                    return false
                })

            // Eventually close the modal manually
            if (ok) {
                if (!this.account.id) {  // New account has been added
                    this.$emit('success:add')
                }

                this.$nextTick(() => {
                    this.$bvModal.hide('account-form-modal')
                })
            }
        },
        generatePassword() {
            let password = Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 7)
            this.$set(this.account, 'password', password)
        },
    },
    computed: {
        ...mapState({
            emails: (state: any) => state.user.profiles.map((x) => x.email).filter(x => typeof(x) === 'string'),
            student_numbers: (state: any) => state.user.profiles
                .map((x) => x.number).filter(x => x)
                .map((x) => String(x))
        }),
        getTitle() {
            if (!this.mounted) return
            if (this.account.id) {
                if (this.account.first_name) return this.account.first_name + ' - Wijzigen'
                return 'Account wijzigen'
            }
            if (this.account.first_name) return this.account.first_name + ' - Toevoegen'
            return 'Account toevoegen'
        },
    },
    mounted() {
        this.mounted = true
    }
}
